
  import { defineComponent, onMounted, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import { ScrollComponent } from "../../../assets/ts/components";
  import { setCurrentPageTitle } from "../../../helper/Breadcrumb";
  import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
  import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
  import { SessionListModel } from "@/domain/hotel-management/session-list/model/SessionListModel";
  import { useStore } from "vuex";
  import SwalNotification from "@/presentation/plugins/SwalNotification";
  import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
  import { useI18n } from "vue-i18n";
import i18n from "@/presentation/language/i18n";
import { timeAgo } from "@/presentation/helper/DateFormatter";
  
  interface KTMessage {
    type: string;
    name?: string;
    image: string;
    time: string;
    text: string;
    emotionAnalysis?: any;
  }
  
  export default defineComponent({
    name: "private-chat",
    components: {
      MessageIn,
      MessageOut,
    },
    setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
  
      const sessionId = ref<any>(Number(route.params["id"]));
  
      const messagesRef = ref<null | HTMLElement>(null);
      const messagesInRef = ref<null | HTMLElement>(null);
      const messagesOutRef = ref<null | HTMLElement>(null);
  
      const swalNotification = new SwalNotification();
  
      const messages = ref<Array<KTMessage>>([]);
      const sessionTitle = ref<string | undefined>();
      const sessionList = ref<SessionListModel[]>([]);
      const sessionListModel: SessionListModel = {
        session: {
        productSessionMessages: []
      },
        page: 1,
        pageSize: 50,
      };
  
      const sessionController = store.state.ControllersModule.sessionController;
  
      const newMessageText = ref("");
  
      watch(
        () => route.params["id"],
        (newValue, oldValue) => {
            if(newValue != oldValue)
            getSessionHistory();
        }
      );

      const getSessionList = () => {
        sessionList.value = [];
        messages.value = [];
  
        sessionController
          .sessionList(sessionListModel)
          .then((response) => {
            if (response.isSuccess) {
              response.getValue().forEach((session) => {
                sessionList.value.push(session);
                if (session.session.id == sessionId.value) {
                  sessionTitle.value = session.session.title
                  session.session.productSessionMessages.forEach((log, index) => {
                    messages.value.push({
                      type: log.owner === "USER" ? "out" : "in",
                      name: log.owner === "USER" ? undefined : "Asistan",
                      image:
                        log.owner === "USER"
                          ? "/media/avatars/blank.png"
                          : "/media/avatars/ai.jpg",
                      time: timeAgo(log.createdAt),
                      text: log.text,
                      emotionAnalysis: log.emotionAnalysis
                    });
                  });
                }
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };

      const getSessionHistory = () => {
        messages.value = [];

        sessionList.value.forEach((session) => {
                if (session.session.id == Number(route.params["id"])) {
                  sessionTitle.value = session.session.title
                  if(session.session.productSessionMessages)
                  session.session.productSessionMessages.forEach((log, index) => {
                    messages.value.push({
                      type: log.owner === "USER" ? "out" : "in",
                      name: log.owner === "USER" ? undefined : "Asistan",
                      image:
                        log.owner === "USER"
                          ? "/media/avatars/blank.png"
                          : "/media/avatars/ai.jpg",
                      time: timeAgo(log.createdAt),
                      text: log.text,
                      emotionAnalysis: log.emotionAnalysis
                    });
                  });
                }
              });
      }
  
      onMounted(() => {
        getSessionList();
        setCurrentPageTitle(t("modals.hotel.title"));
  
        if (!messagesRef.value) {
          return;
        }
  
        ScrollComponent.bootstrap();
      });
  
      return {
        timeAgo,
        messages,
        messagesRef,
        newMessageText,
        messagesInRef,
        messagesOutRef,
        sessionList,
        sessionTitle,
      };
    },
  });
  